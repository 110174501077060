import React from "react"
import {
  DepositUsingSkrillQuery,
  DepositSkrillFormQuery,
} from "../../prismic/staticQueries"
import { connect, useSelector } from "react-redux"
import { config } from "../../constants"
import Layout from "../../components/layout"
import {
  SubscriptionPanel,
  AddFormFields,
} from "../../components/shared"

import {
  Row,
  Col,
  Form,
  Card,
  Container,
} from "react-bootstrap"
const OurMission = () => {
  const skrilParams = config.skrill
  const language = useSelector(state => state.language)
  const { banner_image } = DepositUsingSkrillQuery(language)
  const skrillForm = DepositSkrillFormQuery(language)
  const { primary, items } = skrillForm.body[0]
  return (
    <>
      <Layout>
        <section className="login-register form-background skrill-form-section bgimage biz_overlay overlay--secondary2">
          <div
            className="bg_image_holder"
            style={{ backgroundImage: `url(${banner_image.url})`, opacity: 1 }}
          >
            <div className="content_above">
              <div className="signup-form d-flex align-items-center">
                <Container>
                  <Row>
                    <Col md={3}></Col>
                    <Col md={6}>
                      <div className="form-wrapper">
                        <Card>
                          <Card.Header>
                            <h4 className="text-center">
                              {primary.group_title[0].text}
                            </h4>
                          </Card.Header>
                          <Card.Body>
                            <Form
                              action="https://pay.skrill.com"
                              method="post"
                              target="_blank"
                            >
                              <AddFormFields data={items} label="none" />
                              <input
                                type="hidden"
                                name="recipient_description"
                                value={skrilParams.SKRILL_RECEIPENT_DESCRIPTION}
                              />
                              <input
                                type="hidden"
                                name="logo_url"
                                value={skrilParams.SKRIL_LOGO}
                              />
                              <input
                                type="hidden"
                                name="pay_to_email"
                                value={skrilParams.SKRILL_PAY_TO_EMAIL}
                              />
                              <input
                                type="hidden"
                                name="status_url"
                                value={skrilParams.SKRILL_STATUS_URL}
                              />
                              <input
                                type="hidden"
                                name="language"
                                value={skrilParams.SKRIL_LANGUAGE}
                              />
                              {/* <input type="hidden" name="amount" value="40" /> */}
                              <input
                                type="hidden"
                                name="payment_methods"
                                value={skrilParams.SKRILL_PAYMENT_METHODS}
                              />
                              {/* ,NTL */}
                              <input
                                type="hidden"
                                name="currency"
                                value={skrilParams.SKRILL_CURRENCY}
                              />
                              <input
                                type="hidden"
                                name="detail1_description"
                                value={skrilParams.SKRIL_DETAIL1_DESCRIPTION}
                              />
                              <input
                                type="hidden"
                                name="detail1_text"
                                value={skrilParams.SKRIL_DETAIL1_TEXT}
                              />
                              <div className="form-group text-center m-top-30 m-bottom-20">
                                <button className="btn btn-secondary" type="submit">
                                  {skrillForm.submit_button_text[0].text}
                                </button>
                              </div>
                            </Form>
                          </Card.Body>
                        </Card>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </section>

        <SubscriptionPanel />
      </Layout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps)(OurMission)
